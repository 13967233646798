<template>
  <v-sheet id="container">
    <v-sheet height="calc(100vh - 130px)">
      <v-calendar
        id="calendar"
        first-interval="5"
        interval-count="19"
        ref="calendar"
        :value="focus"
        color="primary"
        :event-overlap-threshold="30"
        event-overlap-mode="stack"
        :events="events"
        :event-color="getEventColor"
        :type="type"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="updateRange"
      >
      </v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-btn
              icon
              to="/calendar/booking"
              @click="displayBottomSheet = true"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-toolbar-title v-html="selectedEvent.eventName"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon to="/calendar/shift" @click="displayBottomSheet = true">
              <v-icon>mdi-heart</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <span v-html="selectedEvent.name"></span>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="secondary" @click="selectedOpen = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-speed-dial
        v-if="this.$store.state.userIsCoreMember"
        v-model="fab"
        style="touch-action: none"
        absolute
        bottom
        right
      >
        <template v-slot:activator>
          <v-btn v-model="fab" v-bind:color="fab ? 'red' : 'green'" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-plus </v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          small
          color="green"
          to="/calendar/booking"
          @click="displayBottomSheet = true"
        >
          <v-icon>mdi-account-group</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color="indigo"
          to="/calendar/shift"
          @click="displayBottomSheet = true"
        >
          <v-icon>mdi-account-clock</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color="orange"
          to="/calendar/meal"
          @click="displayBottomSheet = true"
        >
          <v-icon>mdi-food-turkey</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-sheet>
    <v-bottom-sheet
      v-model="displayBottomSheet"
      scrollable
      transition="bottom-sheet-transition"
    >
      <router-view name="calendarForm"></router-view>
    </v-bottom-sheet>
  </v-sheet>
</template>

<script>
import { firebaseApp } from "../firebase/init";
import "firebase/firestore";

export default {
  data: () => ({
    fab: false,
    fabState: 0,
    fabStates: [
      { icon: "mdi-account-group", color: "green" },
      { icon: "mdi-account-clock", color: "indigo" },
      { icon: "mdi-food-turkey", color: "orange" },
    ],
    start: "",
    end: "",
    firebaseEventsBookings: [],
    firebaseEventsTeamSchedule: [],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    displayBottomSheet: {
      get() {
        return this.$store.state.calendarBottomSheet;
      },
      set(value) {
        this.$store.commit("updateCalendarBottomSheet", value);
      },
    },
    focus() {
      return this.$store.state.calendar.focus;
    },
    type() {
      const displayMethods = ["day", "week", "month", "category"];
      return displayMethods[
        this.$store.state.calendarDrawer.currentCalendarDisplayMethod
      ];
    },
    events() {
      let output = [];
      if (
        this.$store.state.calendarDrawer.currentCalendarDisplayInfoType.includes(
          0
        )
      ) {
        output = output.concat(
          this.firebaseEventsBookings.map((booking) => ({
            eventName: booking.name,
            name: `${booking.start.toDate().toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
            })} ${booking.name}`,
            start: booking.start.toDate(),
            end: booking.end.toDate(),
            color: booking.color,
            timed: booking.timed,
          }))
        );
      }
      if (
        this.$store.state.calendarDrawer.currentCalendarDisplayInfoType.includes(
          1
        )
      ) {
        output = output.concat(
          this.firebaseEventsTeamSchedule.map((shift) => ({
            name: shift.teamMember,
            start: shift.start.toDate(),
            end: shift.end.toDate(),
            color: shift.color,
            timed: true,
          }))
        );
      }
      if (
        this.$store.state.calendarDrawer.currentCalendarDisplayInfoType.includes(
          2
        )
      ) {
        let meals = [];
        for (let booking of this.firebaseEventsBookings) {
          for (let meal of booking.meals) {
            meals.push({
              name: meal.meal,
              start: meal.start.toDate(),
              end: meal.end.toDate(),
              color: "teal",
              timed: true,
            });
          }
          output = output.concat(meals);
        }
      }
      return output;
    },
  },
  methods: {
    fabClicked(fabState) {
      this.fabState = fabState;
    },
    viewDay({ date }) {
      this.$store.commit("calendarViewDay", [date, "day"]);
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      let startDate = new Date(start.date);
      let endDate = new Date(end.date);
      startDate.setDate(startDate.getDate() - 38);
      endDate.setDate(endDate.getDate() + 38);
      firebaseApp
        .firestore()
        .collection("bookings")
        .where("start", ">=", startDate)
        .get()
        .then((querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => doc.data());
          this.firebaseEventsBookings = documents;
        });
      firebaseApp
        .firestore()
        .collection("team-schedule")
        .where("start", ">=", startDate)
        .get()
        .then((querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => doc.data());
          this.firebaseEventsTeamSchedule = documents;
        });
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>
<style>
/* This is for documentation purposes and will not be needed in your application */
#container .v-speed-dial {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

#container .v-btn--floating {
  position: relative;
}
#calendar {
  touch-action: none;
}
</style>
